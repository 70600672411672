<template>
  <div>
    <v-img class="mb-4" v-if="question.image_path" contain>
      <img :src="`${$store.state.def_path}${question.image_path}`" />
    </v-img>
    <h2 class="text-center mb-2">{{ question.text }}</h2>
    <v-text-field
      :type="question.input_type"
      v-model="answer.text"
      lable="الأجابة"
    ></v-text-field>
    <v-alert
      dense
      outlined
      color="error lighten-4"
      class="white--text"
      v-if="
        related_q &&
        (answer.text < question.related.greater_than ||
          answer.text > question.related.less_than)
      "
    >
      يجب ان تكون الاجابة اكبر من ({{ question.related.greater_than }}) و اصغر
      من ({{ question.related.less_than }})
    </v-alert>
  </div>
</template>

<script>
export default {
  props: ["question", "answer", "questions"],
  name: "QText",
  data() {
    return {};
  },
  computed: {
    related_q() {
      let find_q = this.questions.find(
        (e) => e.id == this.question.related.related_id
      );
      return find_q;
    },
  },
};
</script>
