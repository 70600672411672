<template>
  <v-container>
    <v-skeleton-loader
      v-if="!data_loaded"
      type="card, list-item-two-line,list-item-two-line,actions"
    ></v-skeleton-loader>
    <v-card
      :disabled="q_loading"
      :loading="q_loading"
      outlined
      v-if="data_loaded && !form_done && !status_off"
    >
      <v-card-title>
        <span>{{ form_name }}</span>
        <v-spacer></v-spacer>
        ({{ qs.length }} / {{ steps }}) الأسئلة
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="pt-10">
        <div v-for="(q, i) in qs.length" :key="i">
          <div v-if="steps == i + 1">
            <QText
              :answer="answers[i]"
       
              :question="qs[i]"
              :questions="questions"
              v-if="qs[i].type == 'explain' && answers[i].last_answer"
            ></QText>

            <Qmcq
              :questions="qs"
              :answers="answers"
              :question="qs[i]"
              :q_index="i"
              v-else-if="qs[i].type == 'mcq' && answers[i].last_answer"
            ></Qmcq>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center pt-5">
        <v-btn
          depressed
          width="150"
          height="45"
          class="font-weight-bold"
          color="primary"
          @click="check_next()"
          :disabled="next_disabled"
          v-if="steps != qs.length"
          >التالي</v-btn
        >
        <v-btn
          depressed
          width="150"
          height="45"
          class="font-weight-bold"
          color="success"
          :disabled="next_disabled || done_loading"
          :loading="done_loading"
          v-if="steps == qs.length"
          @click="save_form(last_q_answer, true)"
          >تم الانتهاء من الاجابات</v-btn
        >

        <v-btn
          depressed
          width="150"
          height="45"
          color="grey lighten-2"
          class="font-weight-bold"
          v-if="steps != 1"
          @click="check_prev()"
          >السابق</v-btn
        >
      </v-card-actions>
    </v-card>

    <FormMsg v-if="status_off"></FormMsg>

    <FormFinished v-if="form_done"></FormFinished>
  </v-container>
</template>

<script>
import QText from "@/components/customer/occasion/qtext";
import Qmcq from "@/components/customer/occasion/mcq";
import Accept from "@/components/customer/occasion/accept";
import FormFinished from "@/components/customer/occasion/form_finished";
import FormMsg from "@/components/customer/occasion/form_msg";
export default {
  name: "occasion",
  data() {
    return {
      q_loading: false,
      form_done: false,
      done_loading: false,
      data_loaded: false,
      prop: false,
      steps: 1,
      accept: false,
      questions: [{}],
      status_off: false,
      answers: [],
      form_name: "",
      form_id: null,
    };
  },
  components: {
    QText,
    Qmcq,
    Accept,
    FormFinished,
    FormMsg,
  },

  computed: {
    el() {
      return this.steps;
    },
    qs() {
      let questions = [];
      this.questions.forEach((e, i) => {
        if (e.related_with_question) {
          if (this.answers.length != 0) {
            let find_related = this.questions.find(
              (q) => q.id == e.related.related_id
            );
            let find_answers = this.answers.findIndex(
              (an) => an.q_id == find_related.id
            );

            if (find_related.type == "mcq") {
              for (let x = 0; x < e.related.options.length; x++) {
                if (
                  this.answers[find_answers].options.includes(
                    e.related.options[x]
                  )
                ) {
                  questions.push(e);
                  break;
                } else {
                  this.answers[i].options = [];
                  this.answers[i].text = null;
                }
              }
            } else if (find_related.type == "explain") {
              if (this.answers[find_answers].text == e.related.value) {
                questions.push(e);
              } else {
                this.answers[i].options = [];
                this.answers[i].text = null;
              }
            }
          }
        } else {
          questions.push(e);
        }
      });

      // this.last_step = questions.length + 1;
      return questions;
    },
    next_disabled() {
      if (this.answers.length != 0) {
        if (
          this.qs[this.steps - 1].related.related_id &&
          this.qs[this.steps - 1].type == "explain"
        ) {
          let greater = this.qs[this.steps - 1].related.greater_than;
          let less = this.qs[this.steps - 1].related.less_than;

          if (less < 2) {
            return true;
          } else {
            if (
              this.answers[this.steps - 1].text > greater &&
              this.answers[this.steps - 1].text < less
            ) {
              return false;
            } else {
              return true;
            }
          }
        } else {
          if (
            this.answers[this.steps - 1].options.length != 0 ||
            this.answers[this.steps - 1].text
          ) {
            return false;
          } else {
            return true;
          }
        }
      }

      return false;
    },
    last_q_index() {
      let q_index = this.answers.findIndex((e) => e.last_answer == true);
      return q_index;
    },
    last_q_answer() {
      let get_answer = this.answers.find((e) => e.last_answer == true);
      return get_answer;
    },
    prev_last_answer() {
      return this.answers[this.steps - 1];
    },
    active_q() {
      return this.questions[this.steps - 1];
    },
    answered_questions() {
      let answers = [];
      this.answers.forEach((e) => {
        if (e.text || e.options.length != 0) {
          answers.push(e);
        }
      });
      return answers;
    },
  },
  methods: {
    get_form(id) {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `clients/get_form/${id}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (!res.data.update_status) {
            this.status_off = true;
            this.$store.commit("clear_occasion");
          }

          this.form_name = res.data.name;
          this.form_id = res.data.id;
          this.questions = Object.assign([], res.data.questions);
          let answers = res.data.answers;
          this.questions.forEach((e, i) => {
            let find_answer =
              answers.length != 0
                ? answers.answers.find((q) => q.q_id == e.id)
                : null;
            if (!find_answer) {
              this.$set(this.answers, i, {
                id: null,
                q_id: e.id,
                options: [],
                text: null,
                last_answer: false,
                type: e.type,
              });
            } else {
              let is_last = e.id == answers.last_answer;
              if (is_last) {
                this.steps = i + 1;
              }
              this.$set(this.answers, i, {
                id: find_answer.id,
                q_id: e.id,
                options: find_answer.options,
                text: find_answer.text,
                last_answer: is_last,
                type: e.type,
              });
            }
          });

          if (!answers.last_answer) {
            this.answers[0].last_answer = true;
          } else {
            this.steps = this.last_q_index + 1;
          }
          this.data_loaded = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    check_next() {
      if (
        this.answers[this.steps - 1].options.length != 0 ||
        this.answers[this.steps - 1].text
      ) {
        if (this.qs[this.steps]) {
          this.q_loading = true;
          this.answers.forEach((e) => {
            e.last_answer = false;
          });
          this.answers[this.steps].last_answer = true;
          this.save_form(this.prev_last_answer, false);
          this.steps++;
        }
      }
    },
    close_accept() {
      this.accept = false;
      this.answers[this.steps - 1].last_answer = true;
    },
    next_q() {
      if (this.qs[this.steps]) {
        this.q_loading = true;
        this.save_form(this.prev_last_answer, false);
        this.steps++;
      }
    },
    check_prev() {
      if (this.qs[this.steps - 1]) {
        this.steps--;
        this.answers[this.steps - 1].last_answer = true;
        this.$nextTick(() => {
          this.accept = false;
        });
      }
    },
    async save_form(answer, form_done) {
      form_done ? (this.done_loading = true) : (this.done_loading = false);
      await this.$store
        .dispatch("public__request", {
          config: {
            url: "clients/store_form",
            method: "post",
          },
          data: {
            form_id: this.form_id,
            occasion_id: this.$store.state.occasion,
            answer: answer,
            form_done: form_done,
          },
        })
        .then((res) => {
          this.q_loading = false;
          if (form_done) {
            this.form_done = true;
            this.done_loading = false;
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  mounted() {
    if (this.$store.state.occasion) {
      this.get_form(this.$store.state.occasion);
    } else {
      this.$router.push("/check_occasion");
    }
  },
};
</script>
