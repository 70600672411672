<template>
  <div>
    <h2 class="text-center d-block mb-5">   اضافة  {{q_text}}  ؟</h2>
    <div class="d-flex justify-center mb-3">
      <v-btn
        width="150"
        height="45"
        class="font-weight-bold me-2"
        color="blue lighten-5"
        depressed
        @click="$emit('next_q')"
        >نعم</v-btn
      >
      <v-btn
        depressed
        width="150"
        height="45"
        color="blue lighten-5"
        class="font-weight-bold"
        @click="$emit('close_accept')"
        >لا</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "accept",
  props:['q_text', "accept"],
  data() {
    return {

    }
  },

};
</script>
