<template>
  <div class="text-center">
    <v-alert class="mt-10" type="info">
      {{ msg }}
    </v-alert>
    <v-btn rounded x-large color="primary" to="/check_occasion">
        الرجوع للسابقة <v-icon class="ms-2">keyboard_backspace</v-icon> 
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["update_status"],
  data() {
    return {};
  },
  computed: {
    msg() {
      return "تم اغلاق النموذج من قبل الادارة برجاء التواصل للأستفسار وشكرا !!";
    },
  },
};
</script>
