<template>
  <div>
    <v-img class="mb-3" v-if="question.image_path" contain>
      <img :src="`${$store.state.def_path}${question.image_path}`" />
    </v-img>
    <h2 class="text-center">{{ question.text }}</h2>
    <v-row class="mt-5 mb-5 align-end">
      <v-col cols="3" v-for="(opt, i) in question.options" :key="i">
        <v-card
          v-if="opt.image_path"
          @click="select_answer()"
          class="pa-3"
          outlined
          :color="is_selected(opt.id) ? 'primary' : 'white'"
        >
          <v-img height="160px" contain>
            <img
              style="width: 100%; height: 100%"
              :src="`${$store.state.def_path}${opt.image_path}`"
            />
          </v-img>
        </v-card>
        <v-btn
          @click="select_answer(opt)"
          class="mt-2"
          width="100%"
          elevation="0"
          height="45"
          :color="is_selected(opt.id) ? 'primary' : 'primary lighten-4'"
          >{{ opt.text }} &nbsp; ({{ opt_price[i] }} شيكل)</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Qmcq",
  props: ["questions", "question", "answers", "q_index"],
  data() {
    return {};
  },

  computed: {
    is_selected: (app) => (prop) => {
      if (app.answers[app.q_index].options.includes(prop)) {
        return true;
      } else {
        return false;
      }
    },

    opt_price() {
      let price = [];
      this.question.options.forEach((e, i) => {
        if (e.related.q_id) {
          price[i] = 0;
          let related_q_answers = this.answers.find(
            (q) => q.q_id == e.related.q_id
          ).options;
          let related_q = this.questions.find((q) => q.id == e.related.q_id);
          e.related.options_data.forEach((op) => {
            let check_option = related_q_answers.includes(op.id);
            if (check_option) {
              let option = related_q.options.find((o) => o.id == op.id);
              price[i] += option.text * op.option_price;
            }
          });
        } else {
          price[i] = e.price;
        }
      });
      return price;
    },
  },
  methods: {
    select_answer(opt, opt_idnex) {
      if (this.answers[this.q_index].options.length == 0) {
        this.$set(this.answers[this.q_index].options, 0, opt.id);
      } else {
        if (!this.answers[this.q_index].options.includes(opt.id)) {
          if (
            this.question.max_answers >
            this.answers[this.q_index].options.length
          ) {
            this.$set(
              this.answers[this.q_index].options,
              this.answers[this.q_index].options.length,
              opt.id
            );
          } else {
            this.answers[this.q_index].options.splice(
              this.answers[this.q_index].options.length - 1,
              1
            );
            this.$set(
              this.answers[this.q_index].options,
              this.answers[this.q_index].options.length,
              opt.id
            );
          }
        } else {
          let opt_a_index = this.answers[this.q_index].options.findIndex(
            (e) => e == opt.id
          );
          this.answers[this.q_index].options.splice(opt_a_index, 1);
        }
      }
    },
  },
  created() {
    console.log(this.q_index);
  },
};
</script>

<style scoped lang="scss"></style>
