<template>
  <v-card outlined>
    <v-card-text>
      <h2 class="text-center black--text pt-10">تم الانتهاء من النموذج بنجاح</h2>
      <div class="not_found d-flex align-center justify-center">
        <LottieAnimation
          class="anim"
          ref="anim"
          :animationData="require('@/assets/success.json')"
          :loop="true"
        ></LottieAnimation>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
export default {
  name: "FormFinished",
  components: {
    LottieAnimation,
  },
};
</script>
